.all-brands {
  position: absolute;
  width: 75vw;
  left: 0;  // Align with the left edge of the button
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;

  @media only screen and (max-width: 478px) {
    width: 90vw;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent; /* Make it invisible but still clickable */
  z-index: 999; /* Lower than all-brands */
}

.container {
  // width: 80%;
  overflow: hidden;
  position: relative;
  z-index: 1000; /* Ensure it is above the overlay */
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
  background-color: var(--bg-color);
  border-radius: 1em;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 70vh;
  overflow-y: auto;

  .brands {
    column-count: 5;
    gap: 2em;

    @media only screen and (max-width: 920px) {
      column-count: 4;
    }

    @media only screen and (max-width: 478px) {
      column-count: 2;
    }
  }

  .column {
    break-inside: avoid;
    margin-bottom: 2em;

    h5 {
      text-transform: uppercase;
      color: var(--primary-color);
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 0.5em;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 8px;
  }

  a {
    text-decoration: none;
    color: var(--grey-600);

    &:hover {
      text-decoration: underline;
    }
  }
}