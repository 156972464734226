.about-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 2em;

    .about {
        position: relative;
        display: flex;
        height: 80vh;
        overflow: scroll;
        border-radius: 1em;
        flex-direction: column;
        gap: 1em;
        padding: 2em;
        min-width: 35ch;
        max-width: 70ch;
        margin: auto;
        background: var(--bg-color);

        .email {
            font-size: 1em;
            color: var(--primary-color);
            line-height: 150%;
            font-weight: 500;
            text-decoration: underline;
        }

        .icon-grid {
            display: flex;
            flex-direction: row;
            gap: 1em;

            .social-icon {
                width: 2em;
                height: 2em;
                color: var(--primary-color);
                padding: 1em;
                background-color: var(--bg-hover);
                border-radius: 60px;
            }
        }
    }
}

.close-about {
    position: absolute;
    border-radius: 50px;
    border: 1px solid var(--grey-300);
    width: 3em;
    height: 3em;
    border: transparent;
    background: var(--grey-100);
    margin: -1em 1em 0 0;
    box-shadow: 0px 0px 4px #00000036;
    right: 0;
}