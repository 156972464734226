.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2em;

  .modal-content {
    background: var(--bg-color);
    width: 50vw;
    height: 100%;
    overflow: auto;
    /* in case your content is bigger than the screen */
    transition: width 0.3s ease;
    /* smooth transition for resizing */
    border-radius: .75em;

    @media only screen and (max-width: 1200px) {
      width: 90vw;
      padding: 0;
    }
  }

  .modal-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: .5em 1em;
    cursor: pointer;
    border: solid var(--border-color);
    border-width: 0 0 1px 0;
  }
}

.close-email {
  border-radius: 50px;
  border: 1px solid var(--grey-300);
  width: 3em;
  height: 3em;
  border: transparent;
  background: var(--grey-100);
  margin: .5em;
  box-shadow: 0px 0px 4px #00000036;
}

/* For mobile screens */
@media only screen and (max-width: 1200px) {

  .modal-overlay {
    padding: 0;
  }
}