.category-parent {
  flex: 1;
  // padding: 2em;

  @media only screen and (max-width: 1200px) {}

  @media only screen and (max-width: 920px) {}

  @media only screen and (max-width: 776px) {}

  @media only screen and (max-width: 478px) {
    padding: 0em;
  }
}