.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75); // semi-transparent black background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Make sure it covers other content

  .suggest-dialog {
    transform: scale(0.95); // Start slightly scaled down
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    width: 40%;
    position: relative;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    padding: 4em 2em;
    gap: 0.5em;
    align-items: center;
    background-color: var(--bg-color);
    margin: auto;
    // border-top: 1px solid var(--border-color);
    transition: all 0.5s ease-in-out;

    @media only screen and (max-width: 1200px) {
      padding: 2em;
      width: 50%;
    }
    @media only screen and (max-width: 900px) {
      padding: 2em;
      width: 70%;
    }
    @media only screen and (max-width: 776px) {
      width: 100%;
    }

    @media only screen and (max-width: 776px) {
      width: 95%;
    }

    h2,
    h3,
    h5 {
      text-align: center;
      width: 90%;
    }
  }

  .email-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
    
    .carousel-arrow{
      position: absolute;
      right: 1em;
      top: 1em;
    }
  }

  .email-input {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--grey-200);
    border: 1px solid var(--border-color);
    border-radius: 4em;
    overflow: hidden;
    margin-top: 1em;

    @media only screen and (max-width: 776px) {
      width: 90%;
      flex-direction: column;
      border-radius: 1.5em;
      text-align: center;
    }

    input {
      flex-grow: 1;
      border: transparent;
      padding: 1em 1em;
      font-size: 1.25em;
      outline: none !important;
      background: transparent;
    }

    .suggest-button {
      background-color: var(--primary-color);
      font-size: 1.25em;
      color: var(--grey-100);
      padding: 1em;
      border-radius: 2em;
      border-width: 0;
      transition: opacity 0.2s ease, transform 0.2s ease;
      cursor: pointer;

      @media only screen and (max-width: 776px) {
        width: 100%;
        border-radius: 0;
      }
    }
  }

  .length {
    max-width: 25ch;
    text-align: center;
  }

  .error-message,
  .success-message {
    padding-top: 0.5em;
    text-align: center;
  }

  .error-message {
    color: red;
  }

  .success-me  .message.error {
ssage {
    color: var(--primary-color);
  }

  // Add this for the message class
  .message {
    padding-top: 0.5em;
    text-align: center;
    font-weight: 600;
    font-size: 1.25em;
    margin-top: .5em;
  }

    color: red;
  }

  .message.success {
    color: green;
  }
}

.close {
  position: absolute;
  right: 0.4em;
  top: 0.4em;
  border-radius: 50px;
  border: 1px solid var(--grey-300);
  width: 3em;
  height: 3em;
  border: transparent;
  background: var(--grey-100);
  margin: .5em;
  box-shadow: 0px 0px 4px #00000036;
}