.parent {
  margin: auto;
  flex-direction: row;
  padding: 1.5em 0;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: var(--bg-color);

  .bar {
    display: flex;
    gap: 1em;
    align-items: center;

    &-sort-option {
      display: flex;
      flex-direction: row;
      gap: 1em;
    }
  }

  .filter-tag {
    padding: 0.25em;
    border: solid var(--bg-color);
    border-width: 0 0 1px 0;
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 600;
    color: var(--primary-color);
    transition: all 0.15s ease-out;

    &:hover {
      border-color: var(--primary-color);
      transform: translateY(-8%);
    }

    &.selected {
      border-color: var(--primary-color);
    }
  }

  .bar-sort {
    width: max-content;
    border: 1px solid var(--border-color);
    border-radius: 2em;
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 600;
    background-color: var(--bg-color);
    transition: opacity 0.2s ease, transform 0.2s ease;
    display: none;

    &:hover {
      background: var(--bg-hover);
      transform: translateY(-5%);
    }
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(30%) scale(0.9);
    opacity: 0;
  }
  to {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translateX(30%) scale(0.9);
    opacity: 0;
  }
}

.reset-btn-animate-in {
  animation: slideInLeft 0.1s ease-out forwards;
}

.reset-btn-animate-out {
  animation: slideInRight 0.1s ease-out forwards;
}

.filter-tag.in-view {
  border-bottom: 2px solid var(--primary-color);
}

@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 920px) {
  .parent {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 776px){
  .parent {
    flex-direction: row;
    padding: 1em;
    gap: 1em;
  }

  .bar {
    justify-content: start;
    row-gap: 0.5em;
    padding: 0;
    width: 100%;
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

@media only screen and (max-width: 478px) {
  .parent {
    flex-direction: column;
    padding: 1em;
    gap: 1em;
  }
  .bar{
    justify-content: start;
  }
}