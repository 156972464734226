.category-card .category-name {
  width: max-content;
  font-size: 1.1em;
  font-weight: 400;
  // letter-spacing: -.5px;
  color: var(--grey-400); // Default color for unselected state
}

.category-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // overflow: hidden;
  gap: .5em;
  border-radius: 2em;
  padding: .5em 1em;
  border: 1px solid transparent;

  @media only screen and (max-width: 776px) {
    // background-color: var(--bg-color-1);
    // border: 1px solid var(--border-color);
  }

  &:hover {
    background-color: var(--bg-color);
    cursor: pointer;
  }

  &--selected {
    border: 1px solid var(--border-color);
    background-color: var(--bg-color);

    @media only screen and (max-width: 776px) {
      box-shadow: none;
      border-radius: 2em;
    }

    // Change the category name color when selected
    .category-name {
      color: var(--primary-color); // Apply the primary color when selected
    }
  }

  @media only screen and (max-width: 776px) {
    gap: .5em;
    padding: .5em;

    &--selected {
      // background-color: var(--bg-selected);
    }
  }
}

.category-card img {
  width: 28px;
  height: 28px;
  object-fit: cover;

  @media only screen and (max-width: 478px) {
    width: 28px;
    height: 28px;
  }
}