// TabBar.scss
.tabs-bar {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: none;
    position: sticky;
    top: 0;
    background: var(--bg-color-1);
    z-index: 10;
    padding-bottom: 1em;
    gap: 1em;
    height: 3.5em;

    @media only screen and (max-width: 776px) {
      padding: 0 1em 1em 1em ;
    }

    @media only screen and (max-width: 478px) {
      padding: .25em 1em .75em 1em !important;
    }
  }
  
  .tab {
    padding-right: 0;
    padding-left: 0;
    cursor: pointer;
    border: none;
    font-size: 1.1em;
    background: none;
    transition: background-color 0.3s;
    font-weight: 400;
    color: var(--grey-400);
    border-bottom: 2px solid transparent;
  
    &.active {
      border-bottom: 2px solid var(--primary-color);
      font-weight: 500;
      color: var(--primary-color);
    }
  
    &:hover {
      background: var(--bg-hover);
      // border-bottom: 2px solid transparent;
    }
  }