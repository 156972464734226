.parent-home {
    display: flex;
    flex-direction: column;
    /* Change to column to have header at the top */
    position: relative;
    gap: 0em;

    @media only screen and (max-width: 776px) {
        gap: 0;
    }

    .header-brands {
        width: 100%;
        /* Full width to match with parent-category */
    }

    .parent-category {
        position: relative;
        padding: 0em 1em;
        //   width: 25%; /* Adjust width to desired proportion */

        .categories-bar {
            // position: -webkit-sticky;
            display: flex;
            flex-direction: column;
            gap: .25em;
            position: sticky;
            top: 0;
            left: 0;

            @media only screen and (max-width: 920px) {
                background-color: transparent;
            }
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: row;
        /* Row layout for parent-category and header-brands */
        gap: 1em;

        @media only screen and (max-width: 776px) {
            gap: .25em;
        }
    }

    @media only screen and (max-width: 776px) {
        .header-brands {
            padding-bottom: 10em;
        }

        .content-wrapper {
            flex-direction: column;
            /* Stack on top of each other in mobile view */
        }

        .parent-category {
            width: -webkit-fill-available;
            position: relative;
            padding: 0;

            /* Full width on mobile */
            .categories-bar {
                position: fixed;
                width: 100%; // Ensure the bar takes full width
                height: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                top: auto;
                bottom: 0;
                left: 0;
                z-index: 100;
                background-color: var(--bg-color);
                padding: 1em;
                overflow-y: hidden;
                overflow-x: auto;

                @media only screen and (max-width: 776px) {
                    border-top: 1px solid var(--border-color);
                    background-color: var(--bg-color-1);
                }
            }
        }
    }

    .about-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }
}