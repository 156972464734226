/* src/styles/Toast.scss */
.toast {
    visibility: hidden;
    min-width: 250px;
    background-color: var(--grey-600);
    color: var(--grey-100);
    text-align: center;
    border-radius: .75em;
    padding: 1em;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 3em;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    @media only screen and (max-width: 776px) {
        bottom: 8em;
      }
  }
  
  .toast.show {
    visibility: visible;
    opacity: 1;
  }
  