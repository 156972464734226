* {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-weight: 500;

  --border-color: #ebedee;
  --grey-100: #fff;
  --grey-200: #FAFBFA;
  --grey-250: #EBEDEA;
  --grey-300: #D3D4D2;
  --grey-400: #311e00;
  --grey-500: #4c4c4a;
  --grey-600: #252824;
  --grey-600: #0D120C;
  --bg-color: #f8f9fd;

  --bg-color-1: #f1f3fb;

  --bg-hover: var(--bg-color);
  --bg-selected: var(--bg-color);

  --primary-color: #4d2f00;
  --primary-color: var(--grey-600);

  --trophy-color: #d85a00;
  --luggage-color: #1E3A8A;
  --bags-color: #008d67;
  --clothing-color: #800020;
  --tshirt-color: #00526e;
  --sneaker-color: #008791;
  --desktop-color: #008080;
  --selfcare-color: #e64294;
  --games-color: #6113ab;
  --watches-color: #9b5300;
  --shirtfolded-color: #a9002d;

  --max-width: 1600px;
  --min-width: 70vw;
}

body {
  width: 100vw;
  max-width: var(--max-width);
  min-width: var(--min-width);
  background-color: var(--bg-color-1);
  margin: 0 auto;
  font-weight: 300;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  size: 1.2em;
  font-weight: 500;
}

.martian {
  text-decoration: none;
}

.space {
  text-decoration: none;
}

.space-mono {
  text-decoration: none;
}

.bold {
  font-weight: 500;
}

.semibold {
  font-weight: 400;
}

h5 {
  font-size: 1.25em;
  color: var(--grey-600);
  line-height: 150%;
}

h4 {
  font-size: 1.5em;
  color: var(--grey-600);
  line-height: 150%;
}

h3 {
  font-size: 1.75em;
  color: var(--grey-600);
}

h2 {
  font-size: 2.5em;
  color: var(--primary-color);
  line-height: 120%;
}

h1 {
  font-size: 3em;
  color: var(--primary-color);
}

@media only screen and (max-width: 1600px) {
  * {
    font-size: 14px;
  }
}

@media only screen and (max-width: 920px) {}

@media only screen and (max-width: 776px) {}

@media only screen and (max-width: 478px) {

  * {
    font-size: 12px;
  }
}