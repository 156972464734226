.logo-all{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.parent-header {
  width: -webkit-fill-available;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-1);

  .header {
    flex-direction: row-reverse;
    width: -webkit-fill-available;
    padding: .5em 1em .5em 1.5em;
    z-index: 40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.3s ease;

    @media only screen and (max-width: 776px) {
      padding: .5em 1em .5em 1em;
    }

    .logo {
      width: 5em;
      height: auto;

      @media only screen and (max-width: 776px) {
          width: 3.5em;
          height: auto;
      }
  }

    .img {
      display: block;
      opacity: 1;
      margin: 0;

      @media only screen and (max-width: 478px) {
        margin: 0;
      }
    }

    .header-links {
      border-radius: 1em;
      display: flex;
      padding: 0.25em;
      align-items: center;
      gap: 0.5em;

      @media only screen and (max-width: 1200px) {}

      @media only screen and (max-width: 920px) {}
    }

    .a-header {
      font-size: 1.1em;
      font-weight: 600;
      text-decoration: none;
      color: var(--primary-color);
      padding: 0.5em;

      &.active {
        background-color: var(--bg-hover);
        border-radius: 0.5em;
      }

      &:hover {
        background-color: var(--bg-hover);
        border-radius: 0.5em;
      }

      &:visited {
        text-decoration: none;
      }
    }

    .all-brands-button {
      display: flex;
      align-items: center;
      padding: 0.5em 0em;
      background-color: transparent;
      color: var(--primary-color);
      border: none;
      border-radius: .75em;
      cursor: pointer;
      font-weight: 500;
      font-size: 1.1em;
      transition: scale 0.2s ease, transform 0.2s ease;
      transform: scale(1);

      .icon {
        margin-left: 8px;
      }

      &:hover {
        transform: scale(0.97);
        background-color: var(--bg-hover);
      }
    }
  }

  .a-suggest {
    // font-size: 1.5em;
    font-weight: 600;
    text-decoration: none;
    color: var(--primary-color);

    .suggest-button {
      text-decoration: none;
      color: var(--primary-color);
      font-size: 1.1em;
      font-weight: 500;
      background-color: var(--bg-color);
      border: 1px solid var(--border-color) ;
      padding: 0.5em 0.75em;
      border-radius: 2em;
      // border-width: 0;
      transition: scale 0.2s ease, transform 0.2s ease;
      transform: scale(1);
      cursor: pointer;

      &:hover {
        transform: scale(0.97);
      }
    }
  }
}