/* src/styles/BrandGrid.css */
@keyframes spin {
  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.brand-card-fade-in {
  animation: fadeIn .4s ease-out;
}

.l {
  transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);

  &:hover {
    transform: scale(0.8);
  }
}

.r {
  transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);

  &:hover {
    transform: scale(0.8);
  }
}

.brand-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5em;
  margin-bottom: 2em;

  @media only screen and (max-width: 920px) {
    padding-top: 0;
    gap: 0.75em;
  }

  .brand-card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: .75em;
    margin-right: 1em;
    padding-right: 0em;

    &:hover {
      box-shadow: 0px 0px 4px 1px #00000012;
      background-color: var(--bg-color);

      .brand-link {
        border-bottom: 1px solid var(--primary-color);
      }
    }

    @media only screen and (max-width: 920px) {
      margin: 0 .75em 0 0;
      border-radius: .5em;
    }

    @media only screen and (max-width: 776px) {
      padding: 0;
      gap: 0;
      margin: 0;
      border-radius: 0;
      border-width: 0 0 1px 0;
    }

    .copy-info {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 1em 1em 1.5em 1em !important;

      .copy-link {
        display: flex;
        align-items: center;
        padding: .75em;
        border: 1px solid var(--border-color);
        height: fit-content;
        border-radius: 3em;
        background-color: var(--bg-color-1);

        &:hover {
          background-color: var(--bg-color);
        }
      }

      .info-cta {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: .5em;
        align-items: flex-start;

        .name {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          gap: .3em;

          h3 {
            font-size: 1.5em;

            @media only screen and (max-width: 776px) {
              font-size: 1.5em;
            }
          }
        }

        .details {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          gap: .3em;
        }

        .brand-link {
          display: flex;
          flex-direction: row;
          align-content: center;
          align-items: center;
          width: fit-content;
          border-bottom: 1px solid transparent;
          text-decoration: none;
          color: var(--primary-color);
          font-weight: 500;
          font-size: 1.2em;
          transition: border-bottom 0.2s ease-in-out;

          &:hover {
            border-bottom: 1px solid var(--primary-color);
          }
        }
      }
    }

    .brand-tag {
      margin: 0;
      font-size: 1.1em;
      color: var(--grey-400);
      text-transform: uppercase;
      line-height: 150%;
      font-weight: 500;

      @media only screen and (max-width: 776px) {
        font-size: 1em;
      }
    }
  }
}

/* Responsive adjustments */
@media only screen and (max-width: 2000px) {}

@media only screen and (max-width: 1200px) {
  .brand-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .placeholder-image {
    width: 30vw;
  }
}

@media only screen and (max-width: 920px) {
  .brand-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .placeholder-image {
    width: 40vw;
  }
}

@media only screen and (max-width: 776px) {
  .brand-grid {
    grid-template-columns: repeat(1, 1fr);

    .name {
      flex-direction: column-reverse;
    }
  }

  .placeholder-image {
    width: 80vw;
  }
}

@media only screen and (max-width: 478px) {
  .brand-grid {
    grid-template-columns: repeat(1, 1fr);
    row-gap: -1px !important;
    gap: -1px !important;
  }

  .placeholder-image {
    width: 80vw;
  }

  .brand-card {
    .info-cta {
      align-items: flex-start !important;
      border-bottom: 0px !important;
      gap: .3em !important;
    }

    .brand-tag {
      font-size: 1.1em !important;
    }
  }
}