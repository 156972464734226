.carousel {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    position: relative;
    max-width: 100%;
    gap: .5em;
    padding: 1em 0 0 1em;
    // border-radius: .5em;
    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 478px) {
      padding: 1em 0 0 1em;
    }
  
    .img-container {
      flex: 0 0 auto;
      position: relative;
      width: 30%;
      overflow: none;
      max-width: 40%;

      @media only screen and (max-width: 900px) {
        width: 50%;
        max-width: 60%;
      }
  
      @media only screen and (max-width: 776px){
        width: 55%;
        max-width: 70%;
      }
  
      @media only screen and (max-width: 478px) {
        width: 80%;
        max-width: 80%;
      }
  
      &:hover .carousel-image-link {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
      }
    }
  
    &-image {
      object-position: top;
      height: 25em;
      object-fit: cover;
      width: 100%;
      border-radius: .75em;
      border: 1px solid var(--border-color);
      // border: 1px solid var(--border-color);
      
      @media only screen and (max-width: 900px) {
        height: 22em;
      }

      @media only screen and (max-width: 478px) {
        height: 24em;
      }
    }
  
    &-image-link {
      opacity: 1;
      // transform: translateX(-3px) translateY(3px);
      position: absolute;
      bottom: 1em;
      right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background: var(--grey-100);
      width: 3em;
      height: 3em;
      margin: 0.5em;
      text-align: center;
      box-shadow: 0px 0px 4px #00000036;
      cursor: pointer;
      transition: transform 0.15s ease-in-out;
  
      .icon {
        transition: opacity 0.2s ease;
      }
    }
  
    &-dots {
      display: none;
    }
  }